import React from 'react';
import { navigate } from 'gatsby';

import { checkSize } from '../Responsive/context';
import { withCart } from './context';

import ButtonHidden from '../Button/hidden';

import { CartIcon } from './icons';
import Spinner from '../Placeholder/spinner';

import { Bold, MainColour } from '../../utils/variables';

import loadComponent from '../Loadable';

const Portal = loadComponent('portal');
const CartItems = loadComponent('cart-items');

class Cart extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener('mousedown', this.clickOff, false);
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.closeCart, { passive: true });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener('mousedown', this.clickOff, false);
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.closeCart, false);
  };

  clickOff = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.closeCart();
  };

  openCart = e => {
    e.preventDefault();
    const { windowXL } = this.props;
    const { showDropdown } = this.state;
    if (windowXL) {
      if (this._isMounted) {
        this.setState({ showDropdown: !showDropdown });
      }
    } else {
      navigate('/basket/');
    }
  };

  closeCart = () => {
    if (this._isMounted) {
      this.setState({ showDropdown: false });
    }
  };

  render() {
    const { showDropdown } = this.state;
    const { position, count } = this.props;
    return (
      <>
        <ButtonHidden
          onClick={this.openCart}
          style={{
            display: `flex`,
            alignItems: `center`,
            position: `relative`,
          }}
        >
          <CartIcon />
          <span
            style={{
              ...badgeShared,
              ...badgeOuter,
            }}
          >
            <span
              style={{
                ...badgeShared,
                ...badgeInner,
              }}
            >
              {count}
            </span>
          </span>
        </ButtonHidden>
        {showDropdown && (
          <Portal portal="dropdown">
            <div
              className="cartDropdown"
              style={{
                ...dropdownStyle,
                right: `${position}px`,
                zIndex: 999,
              }}
              ref={node => {
                this.node = node;
              }}
            >
              <CartItems
                fallback={
                  <Spinner
                    size="36px"
                    style={{ margin: `0 auto`, display: `block` }}
                  />
                }
              />
            </div>
          </Portal>
        )}
      </>
    );
  }
}

export default checkSize(withCart(Cart));

const badgeShared = {
  height: `0.9375rem`,
  width: `0.9375rem`,
  borderRadius: `0.9375rem`,
  position: `absolute`,
  lineHeight: 1,
};

const badgeOuter = {
  backgroundColor: MainColour,
  boxSizing: `border-box`,
  top: `-2px`,
  left: `18px`,
};

const badgeInner = {
  display: `inline-flex`,
  alignItems: `center`,
  justifyContent: `center`,
  color: `#fff`,
  fontSize: `0.5rem`,
  fontWeight: Bold,
  top: `0px`,
  left: 0,
};

const dropdownStyle = {
  backgroundColor: `#fff`,
  borderRadius: ``,
  position: `fixed`,
  top: `226px`,
  animation: `dropdown 0.3s forwards`,
  padding: `1.875rem 2.8125rem`,
  border: `1px solid rgba(0,0,0,0.1)`,
  boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.1)`,
  width: `420px`,
  maxWidth: `100%`,
};
