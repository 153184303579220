import React from 'react';

export default function ButtonHidden({ children, style, ...props }) {
  return (
    <button
      type="button"
      style={{
        display: `inline-block`,
        verticalAlign: `top`,
        background: `transparent`,
        border: 0,
        outline: 0,
        padding: 0,
        cursor: `pointer`,
        lineHeight: 1,
        ...style,
      }}
      {...props}
    >
      {children}
    </button>
  );
}
